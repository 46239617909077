<template>
    <div class="page korder-record" :class="{ 'show-menu': switchSelectIndex == 0 }">
        <div class="page-header">
            <div class="switch-order-list">
                <div
                    class="switch-order-item"
                    @click="clickSwitch(0)"
                    :class="{ select: switchSelectIndex == 0 }"
                >
                    <div class="value">堂食/外卖</div>
                </div>
                <div
                    class="switch-order-item"
                    @click="clickSwitch(1)"
                    :class="{ select: switchSelectIndex == 1 }"
                >
                    <div class="value">外送</div>
                </div>
            </div>
            <div class="menu-list" v-if="switchSelectIndex == 0">
                <div
                    class="menu-item"
                    :class="{ select: menuSelectIndex == 0 }"
                    @click="clickMenu(0)"
                >
                    全部订单
                </div>
                <div
                    class="menu-item"
                    :class="{ select: menuSelectIndex == 1 }"
                    @click="clickMenu(1)"
                >
                    礼品卡订单
                </div>
                <div
                    class="menu-item"
                    :class="{ select: menuSelectIndex == 2 }"
                    @click="clickMenu(2)"
                >
                    权益订单
                </div>
            </div>
        </div>
        <div class="record-ul">
            <div id="padding-top"></div>
            <div
                class="record-li"
                v-for="(item, index) in recordData"
                :key="index"
                @click="toOrderInfo(index)"
            >
                <div class="title">
                    共{{ item.kfcPlaceOrder.items.length }}份餐品
                    <span class="delivery" v-if="item.delivery">(美团配送)</span>
                </div>
                <div class="time">{{ item.createTime }}</div>
                <img :src="item.showProductImg" class="img" />
                <div
                    class="status-desc"
                    :class="[
                        { 'line-up': item.status == 5 },
                        { cancel: item.status == -5 },
                        { payment: item.status == 0 },
                        { over: item.status == 15 },
                    ]"
                >
                    {{ item.statusDesc }}
                </div>
                <div class="show-product-name">{{ item.showProductName }}</div>
                <div class="total">{{ item.totalPrice || 0 }}</div>
            </div>
            <div class="padding-bottom iphone-x-adaptation"></div>
        </div>
        <div class="not-data" v-if="recordData.length == 0">暂无订餐记录！</div>
        <Scroll :onBottom="onBottom" :lowerThreshold="200" />
    </div>
</template>

<script>
import { GET_KFC_ORDER_LIST } from 'api';
import { PAGE_ORDER_INFO, PAGE_ROOT } from 'page';
import Scroll from '@/components/scroll';
export default {
    data() {
        return {
            recordData: [],
            pageIndex: 1,
            pageSize: 20,
            isBottom: false,
            switchSelectIndex: 0,
            menuSelectIndex: 0,
            takeout: null,
        };
    },
    components: {
        Scroll,
    },
    created() {
        this.getData();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.params.backToIndex) {
                vm.$util.pageJump({
                    path: PAGE_ROOT,
                    config: {
                        jumpType: 1,
                    },
                });
            }
        });
    },
    methods: {
        getData(type = 0) {
            this.$loading.open('加载中...');
            if (type == 1) {
                this.pageIndex = 1;
            }
            if (this.switchSelectIndex == -1) {
                this.takeout = null;
            } else if (this.switchSelectIndex == 0) {
                this.takeout = false;
            } else if (this.switchSelectIndex == 1) {
                this.takeout = true;
            }
            this.$util
                .post(GET_KFC_ORDER_LIST, {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    takeout: this.takeout,
                    tag: this.menuSelectIndex == 0 ? null : this.menuSelectIndex,
                })
                .then(res => {
                    this.$loading.close();
                    if (res.success) {
                        if (res.data.length < this.pageSize) {
                            this.isBottom = true;
                        } else {
                            this.isBottom = false;
                        }
                        for (var i = 0; i < res.data.length; i++) {
                            var item = res.data[i];
                            item.showProductImg = item.kfcPlaceOrder.items[0].imageUrl;
                            item.showProductName = item.kfcPlaceOrder.items[0].productName;
                            if (item.kfcPlaceOrder.items.length > 1) {
                                item.showProductName += '等';
                            }
                        }
                        if (type == 1) {
                            this.recordData = [...[], ...res.data];
                        } else {
                            this.recordData = [...this.recordData, ...res.data];
                        }
                        document.getElementById('padding-top').scrollIntoView();
                    }
                });
        },
        // 底部下来
        onBottom() {
            if (this.isBottom) {
                return;
            }
            this.pageIndex++;
            this.getData();
        },
        // 去订单详情
        toOrderInfo(index) {
            const item = this.recordData[index];
            this.$util.pageJump(PAGE_ORDER_INFO, {
                // orderId: item.id,
                orderNo: item.orderNo,
                sourceType: 2,
            });
        },
        clickSwitch(index) {
            if (this.switchSelectIndex == index) {
                this.switchSelectIndex = -1;
            } else {
                this.switchSelectIndex = index;
            }
            this.menuSelectIndex = 0;
            this.getData(1);
        },
        clickMenu(index) {
            this.menuSelectIndex = index;
            this.getData(1);
        },
    },
};
</script>

<style lang="less" scoped>
@import url('~@/lib/base.less');
@swithHeight: .pxToRem(184) [];
@menuListHeight: .pxToRem(80) [];

.show-menu {
    @swithHeight: calc(.pxToRem(184) [] + @menuListHeight);
    .page-header {
        height: @swithHeight;
    }
    .record-ul {
        #padding-top {
            height: calc(@swithHeight + .pxToRem(10) []);
        }
    }
}

.page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: @swithHeight;
    background: white;
    box-shadow: .pxToRem(3) [] .pxToRem(3) [] .pxToRem(8) [] 0px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 .pxToRem(40) [] .pxToRem(40) [];
    z-index: 10;
    .switch-order-list {
        display: flex;
        justify-content: space-around;
    }
    .switch-order-item {
        width: .pxToRem(315) [];
        text-align: center;
        height: .pxToRem(128) [];
        line-height: .pxToRem(128) [];
        margin-top: .pxToRem(20) [];
        border: .pxToRem(1) [] solid #313131;
        border-radius: .pxToRem(20) [];
        box-sizing: border-box;
        .value {
            display: inline-block;
            font-size: .pxToRem(30) [];
            color: rgba(0, 0, 0, 0.9);
            height: .pxToRem(40) [];
            line-height: .pxToRem(40) [];
            text-indent: .pxToRem(88) [];
            &:before {
                content: ' ';
                .base-center-y;
                left: 0;
                .sq(72);
            }
        }
        &.select {
            border: .pxToRem(1) [] solid @theme_color_first;
            background: #fdf0f0;
            color: @theme_color_first;
        }
        &:first-child {
            .value {
                &:before {
                    background: url('~@/assets/icons/icon-store-in.png');
                    background-size: cover;
                }
            }
        }
        &:last-child {
            .value {
                &:before {
                    background: url('~@/assets/icons/icon-take-out.png');
                    background-size: cover;
                }
            }
        }
    }
    .menu-list {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: @menuListHeight;
        padding: 0 .pxToRem(30) [];
        .menu-item {
            padding: 0 .pxToRem(40) [];
            height: .pxToRem(60) [];
            line-height: .pxToRem(60) [];
            border: .pxToRem(1) [] solid #313131;
            border-radius: .pxToRem(12) [];
            box-sizing: border-box;
            &.select {
                border: .pxToRem(1) [] solid @theme_color_first;
                background: #fdf0f0;
                color: @theme_color_first;
            }
        }
    }
}

.record-ul {
    padding-left: .pxToRem(20) [];
    padding-right: .pxToRem(20) [];
    #padding-top {
        height: calc(@swithHeight + .pxToRem(10) []);
    }
    .padding-bottom {
        height: @tabbarHeight;
    }
    .record-li {
        height: .pxToRem(240) [];
        box-shadow: .pxToRem(3) [] .pxToRem(3) [] .pxToRem(8) [] 0px rgba(0, 0, 0, 0.1);
        background: white;
        border-radius: .pxToRem(30) [];
        margin-bottom: .pxToRem(10) [];
        .title {
            position: absolute;
            top: .pxToRem(30) [];
            left: .pxToRem(40) [];
            height: .pxToRem(40) [];
            line-height: .pxToRem(40) [];
            font-size: .pxToRem(30) [];
            font-weight: 600;
            .delivery {
                font-size: .pxToRem(24) [];
                font-weight: 400;
                color: @theme_color_first;
            }
        }
        .img {
            position: absolute;
            left: .pxToRem(40) [];
            bottom: .pxToRem(35) [];
            max-width: .pxToRem(165) [];
            max-height: .pxToRem(120) [];
        }
        .show-product-name {
            position: absolute;
            top: .pxToRem(85) [];
            left: .pxToRem(230) [];
            right: .pxToRem(180) [];
            line-height: .pxToRem(40) [];
            height: .pxToRem(80) [];
            color: rgba(0, 0, 0, 0.9);
            font-size: .pxToRem(28) [];
            .overflow(2);
        }
        .time {
            position: absolute;
            bottom: .pxToRem(30) [];
            left: .pxToRem(230) [];
            height: .pxToRem(36) [];
            line-height: .pxToRem(36) [];
            color: rgba(0, 0, 0, 0.5);
            font-size: .pxToRem(24) [];
        }
        .status-desc {
            position: absolute;
            top: .pxToRem(30) [];
            right: .pxToRem(30) [];
            height: .pxToRem(44) [];
            line-height: .pxToRem(44) [];
            font-size: .pxToRem(28) [];
            &.line-up {
                color: #13ce66;
            }
            &.cancel {
                color: rgba(0, 0, 0, 0.3);
            }
            &.payment {
                color: @theme_color_first;
            }
            &.over {
                color: rgba(0, 0, 0, 0.5);
            }
        }
        .total {
            position: absolute;
            top: .pxToRem(85) [];
            right: .pxToRem(30) [];
            height: .pxToRem(40) [];
            line-height: .pxToRem(40) [];
            font-weight: 600;
            color: rgba(0, 0, 0, 0.9);
            font-size: .pxToRem(30) [];
            padding-left: .pxToRem(24) [];
            &:before {
                content: '¥';
                position: absolute;
                left: 0;
                bottom: 0;
                height: .pxToRem(36) [];
                line-height: .pxToRem(36) [];
                width: .pxToRem(24) [];
                text-align: center;
                font-weight: 400;
                font-size: .pxToRem(24) [];
            }
        }
    }
}
.not-data {
    .base-center;
    font-size: .pxToRem(28) [];
}
</style>
